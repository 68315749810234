import React, { useEffect } from "react";
import { Drawer, Image, Layout, Menu, MenuProps } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";
import { useUser } from "$/state/user";
import { ROUTE_PATHS } from "$/configs/routes";
import { WEB_ASSETS_URL } from "$/configs/app.config";
import { AccountMenu } from "$/components/ContextMenus/Account";
import { useIsMobile } from "$/hooks";
import { useCompanySubscription } from "$/state/company-subscription";

const { Header } = Layout;

const BrowseIcon = () => (
  <span role="img" className="anticon anticon-solution ant-menu-item-icon">
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.62988 17.4375C14.2898 17.4375 18.0674 13.6599 18.0674 9C18.0674 4.3401 14.2898 0.5625 9.62988 0.5625C4.96998 0.5625 1.19238 4.3401 1.19238 9C1.19238 13.6599 4.96998 17.4375 9.62988 17.4375Z"
        fill="#F7C421"
      />
      <path
        d="M14.1299 9H5.12988M9.62988 4.5V13.5V4.5Z"
        stroke="#1E1E1E"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </span>
);

const TrainingIcon = () => (
  <span role="img" className="anticon anticon-solution ant-menu-item-icon">
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.87988 13.5L5.09863 16.3125L6.22363 10.9688L2.28613 7.3125L7.62988 6.75L9.87988 1.6875L12.1299 6.75L17.4736 7.3125L13.5361 10.9688L14.6611 16.3125L9.87988 13.5Z"
        fill="#40B16B"
        stroke="#40B16B"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </span>
);

const GuidesIcon = () => (
  <span role="img" className="anticon anticon-solution ant-menu-item-icon">
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3.72363 0.5625V17.4375H15.5361V5.0625L11.0361 0.5625H3.72363Z" fill="white" />
      <path d="M11.0361 0.5625V5.0625H15.5361" fill="#769BB9" />
      <path
        d="M5.87988 9.5625H13.3799M5.87988 11.8125H13.3799M5.87988 14.0625H9.25488"
        stroke="#1E1E1E"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </span>
);

const ToolsIcon = () => (
  <span role="img" className="anticon anticon-solution ant-menu-item-icon">
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.87988 3.9375C3.81186 3.9375 4.56738 3.18198 4.56738 2.25C4.56738 1.31802 3.81186 0.5625 2.87988 0.5625C1.9479 0.5625 1.19238 1.31802 1.19238 2.25C1.19238 3.18198 1.9479 3.9375 2.87988 3.9375Z"
        fill="#F7C421"
        stroke="#F7C421"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.3799 3.9375C17.3119 3.9375 18.0674 3.18198 18.0674 2.25C18.0674 1.31802 17.3119 0.5625 16.3799 0.5625C15.4479 0.5625 14.6924 1.31802 14.6924 2.25C14.6924 3.18198 15.4479 3.9375 16.3799 3.9375Z"
        fill="#F65800"
        stroke="#F65800"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.62988 3.9375C10.5619 3.9375 11.3174 3.18198 11.3174 2.25C11.3174 1.31802 10.5619 0.5625 9.62988 0.5625C8.6979 0.5625 7.94238 1.31802 7.94238 2.25C7.94238 3.18198 8.6979 3.9375 9.62988 3.9375Z"
        fill="#F7A000"
        stroke="#F7A000"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.87988 17.4375C3.81186 17.4375 4.56738 16.682 4.56738 15.75C4.56738 14.818 3.81186 14.0625 2.87988 14.0625C1.9479 14.0625 1.19238 14.818 1.19238 15.75C1.19238 16.682 1.9479 17.4375 2.87988 17.4375Z"
        fill="#3A5276"
        stroke="#3A5276"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.3799 17.4375C17.3119 17.4375 18.0674 16.682 18.0674 15.75C18.0674 14.818 17.3119 14.0625 16.3799 14.0625C15.4479 14.0625 14.6924 14.818 14.6924 15.75C14.6924 16.682 15.4479 17.4375 16.3799 17.4375Z"
        fill="#93B31B"
        stroke="#93B31B"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.62988 17.4375C10.5619 17.4375 11.3174 16.682 11.3174 15.75C11.3174 14.818 10.5619 14.0625 9.62988 14.0625C8.6979 14.0625 7.94238 14.818 7.94238 15.75C7.94238 16.682 8.6979 17.4375 9.62988 17.4375Z"
        fill="#769BB9"
        stroke="#769BB9"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.87988 10.6875C3.81186 10.6875 4.56738 9.93198 4.56738 9C4.56738 8.06802 3.81186 7.3125 2.87988 7.3125C1.9479 7.3125 1.19238 8.06802 1.19238 9C1.19238 9.93198 1.9479 10.6875 2.87988 10.6875Z"
        fill="#FF9394"
        stroke="#FF9394"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.3799 10.6875C17.3119 10.6875 18.0674 9.93198 18.0674 9C18.0674 8.06802 17.3119 7.3125 16.3799 7.3125C15.4479 7.3125 14.6924 8.06802 14.6924 9C14.6924 9.93198 15.4479 10.6875 16.3799 10.6875Z"
        fill="#006A39"
        stroke="#006A39"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.62988 10.6875C10.5619 10.6875 11.3174 9.93198 11.3174 9C11.3174 8.06802 10.5619 7.3125 9.62988 7.3125C8.6979 7.3125 7.94238 8.06802 7.94238 9C7.94238 9.93198 8.6979 10.6875 9.62988 10.6875Z"
        fill="#93541C"
        stroke="#93541C"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </span>
);

const OneOhOneIcon = () => (
  <span role="img" className="anticon anticon-solution ant-menu-item-icon">
    <svg width="16" height="16" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.37988 3.5C9.37988 1.25 13.8799 0.6875 17.8174 0.6875V13.0625C13.8799 13.0625 9.37988 13.625 9.37988 15.875"
        fill="#006A39"
      />
      <path
        d="M9.37988 3.5C9.37988 1.25 13.8799 0.6875 17.8174 0.6875V13.0625C13.8799 13.0625 9.37988 13.625 9.37988 15.875"
        stroke="#006A39"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.37988 3.5C9.37988 1.25 4.87988 0.6875 0.942383 0.6875V13.0625C4.87988 13.0625 9.37988 13.625 9.37988 15.875V3.5Z"
        fill="#F7C421"
        stroke="#F7C421"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </span>
);

const mainMenuItems: MenuProps["items"] = [
  {
    key: "how-to-use-coaching",
    icon: <OneOhOneIcon />,
    label: <NavLink to={ROUTE_PATHS.ONBOARDING_VIDEOS}>How to use Coaching</NavLink>,
  },
  {
    key: "book-a-session",
    icon: <BrowseIcon />,
    label: <NavLink to={ROUTE_PATHS.SESSIONS.BOOK_COACHING.BROWSE}>Book Coaching</NavLink>,
  },
  {
    key: "tools",
    icon: <ToolsIcon />,
    label: <NavLink to={ROUTE_PATHS.TOOLS.MAIN}>Tools</NavLink>,
  },
  {
    key: "training",
    icon: <TrainingIcon />,
    label: <NavLink to={"https://morehappi.com/school-of-more-happi"}>Training</NavLink>,
  },
];

const coachMenuItems: MenuProps["items"] = [
  {
    key: "how-to-use-coaching",
    icon: <OneOhOneIcon />,
    label: (
      <NavLink
        to={
          "https://www.notion.so/The-Coach-Handbook-73a5e138483447acb937ad00dadbe3c5?p=307af46393774afd92967d88de92c5e9&pm=c"
        }
        target="_blank"
        rel="noreferrer"
      >
        Coach Handbook
      </NavLink>
    ),
  },
  {
    key: "tools",
    icon: <TrainingIcon />,
    label: (
      <NavLink
        to={"https://www.notion.so/Supervision-f1bf7975ae124e9ea1d0f713ec0f4f56"}
        target="_blank"
        rel="noreferrer"
      >
        Supervision Sessions
      </NavLink>
    ),
  },
  {
    key: "training",
    icon: <ToolsIcon />,
    label: (
      <NavLink
        to={"https://www.notion.so/Happi-Hour-03a60e94338e483aa8c6ac0576d99add"}
        target="_blank"
        rel="noreferrer"
      >
        Happi Hour Training
      </NavLink>
    ),
  },
  {
    key: "book-a-session",
    icon: <BrowseIcon />,
    label: <NavLink to={ROUTE_PATHS.SESSIONS.BOOK_COACHING.BROWSE}>Book Coaching</NavLink>,
  },
];

const MobileMenu = ({ menuItems }) => {
  const [open, setOpen] = React.useState(false);
  const location = useLocation();

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setOpen(false);

    if (window) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return (
    <>
      <MenuOutlined onClick={showDrawer} />
      <Drawer
        title={
          <Image
            className="logo"
            height={64}
            src={`${WEB_ASSETS_URL}/More_Happi_logo.svg`}
            alt="MoreHappi"
            preview={false}
          />
        }
        width="100%"
        placement="right"
        onClose={onClose}
        open={open}
      >
        <Menu
          defaultSelectedKeys={[]}
          mode="vertical"
          theme="light"
          className="mh-menu"
          disabledOverflow
          items={menuItems}
        />
        <AccountMenu mobile />
      </Drawer>
    </>
  );
};

const NonMobileMenu = ({ menuItems }) => (
  <>
    <Menu
      defaultSelectedKeys={[]}
      mode="horizontal"
      theme="light"
      className="mh-menu"
      disabledOverflow
      items={menuItems}
    />
    <AccountMenu />
  </>
);

export const Navigation = () => {
  const user = useUser();
  const companySubscription = useCompanySubscription();
  const isMobile = useIsMobile();

  const menuItems = mainMenuItems.filter((item) => {
    if (user?.currentUser?.activePackage?.id && item.key === "book-a-session") return false;
    if (!user?.currentUser?.activePackage?.id && item.key === "book-ad-hoc-session") return false;
    if (
      !companySubscription.state.subscriptionCustomer &&
      (item.key === "book-ad-hoc-session" || item.key === "book-a-session")
    )
      return false;
    return true;
  });

  return (
    <Header className="mh-nav-header">
      <NavLink to="/" className="mh-nav-logo">
        <Image
          className="logo"
          height={64}
          src={`${WEB_ASSETS_URL}/More_Happi_logo.svg`}
          alt="MoreHappi"
          preview={false}
        />
      </NavLink>
      {isMobile ? (
        <MobileMenu menuItems={user?.currentUser?.coach?.id ? coachMenuItems : menuItems} />
      ) : (
        <NonMobileMenu menuItems={user?.currentUser?.coach?.id ? coachMenuItems : menuItems} />
      )}
    </Header>
  );
};
