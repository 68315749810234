import { Enquiry } from "$/graphql/types.generated";

export const getResultsParams = (enquiry: Enquiry) => {
  const experienceChoices = enquiry?.enquiryChoices
    ?.find((choice) => choice.key === "someoneExperiencedWith")
    ?.value?.split(",");

  let flexible = false;

  if (experienceChoices?.includes("I&apos;m-flexible")) {
    flexible = true;
  }

  const approach = [];
  const experience = [];
  if (experienceChoices?.includes("Someone-loose-and-exploratory")) {
    approach.push("loose_and_exploratory");
  }
  if (experienceChoices?.includes("Someone-firm-and-structured")) {
    approach.push("highly_structured");
    approach.push("firm");
  }
  if (experienceChoices?.includes("Someone-experienced-with-neurodivergent-people")) {
    experience.push("neurodiverse");
  }
  if (experienceChoices?.includes("Someone-experienced-with-women")) {
    experience.push("women");
  }
  if (experienceChoices?.includes("Someone-experienced-with-leaders")) {
    experience.push("leaders");
  }
  if (experienceChoices?.includes("Someone-experienced-with-commercial-employees")) {
    experience.push("comercial_employees");
  }
  if (experienceChoices?.includes("Someone-experienced-with-board-members")) {
    experience.push("board_members");
  }
  if (experienceChoices?.includes("Someone-experienced-with-managing-directors-/-CEOs")) {
    experience.push("managing_directors");
  }
  if (experienceChoices?.includes("Someone-experienced-with-first-time-managers")) {
    experience.push("first_time_managers");
  }
  if (experienceChoices?.includes("Someone-experienced-with-managers")) {
    experience.push("managers");
  }
  if (experienceChoices?.includes("Someone-experienced-with-team-leads")) {
    experience.push("team_leads");
  }

  const approachParams = approach
    .map((param) => `&approaches=${encodeURIComponent(param)}`)
    .join("");
  const experienceParams = experience
    .map((param) => `&audienceExperiences=${encodeURIComponent(param)}`)
    .join("");

  return `/coaches?ctx=enquiry&eid=${enquiry?.id}${approachParams}${experienceParams}`;
};
