import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { Col, Input, Layout, Row } from "antd";
import qs from "query-string";
import { debounce } from "lodash/fp";
import { useScrollTo } from "$/hooks";
import { HtmlMeta } from "$/components/HtmlMeta";
import { LoadingSpinner } from "$/components/LoadingSpinner";
import { CategoriesFilter } from "$/components/CoachFilters/Categories";
import { ApproachesFilter } from "$/components/CoachFilters/Approaches";
import { AudienceExperienceFilter } from "$/components/CoachFilters/AudienceExperience";
import { SpecialismsFilter } from "$/components/CoachFilters/Specialisms";
import { CoachCard } from "$/components/CoachCard";
import { CompanyFeaturePermissions } from "$/state/company";
import { usePermissionRedirect } from "$/hooks/usePermissionRedirect";
import { Mixpanel } from "$/tracking";
import { IPageBaseProps } from "$/interfaces";
import {
  UserRole,
  useSearchCoachesByAvailabilityQuery,
  useUpdateUserPackageMutation,
} from "$/graphql/types.generated";
import styles from "./styles.module.less";
import { usePageCount } from "./hooks";
import { Button } from "$/components/Button";
import { ArrowLeft } from "lucide-react";
import { useUser } from "$/state/user";
import { DateTime } from "luxon";
import { ROUTE_PATHS } from "$/configs/routes";
import { getConfirmUrl } from "../Coach";
import { CalendarOutlined } from "@ant-design/icons";
import StickyBanner from "$/components/Banner/StickyBanner";

interface IProps extends IPageBaseProps {}

const allowableFilters = ["categories", "audienceExperiences", "specialisms", "approaches"];

const getWhereCoachQuery = (filters: any): any => {
  const withFilters = Object.entries(filters).reduce((where, [filterType, filterValue]: any) => {
    if (!allowableFilters.includes(filterType)) return where;

    return {
      ...where,
      [filterType]: {
        some: {
          value: {
            in: Array.isArray(filterValue) ? filterValue : [filterValue],
          },
        },
      },
    };
  }, {});

  return {
    ...withFilters,
  };
};

const COACHES_PER_PAGE = 4;

export const Coaches: React.FC<IProps> = () => {
  useScrollTo();
  usePermissionRedirect(CompanyFeaturePermissions.coachDirectory);

  const user = useUser();
  const location = useLocation();
  const navigate = useNavigate();
  const query = qs.parse(location.search);
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState(getWhereCoachQuery(query));
  const [searchQuery, setSearchQuery] = useState(searchParams.get("search"));
  const [currentPage, setCurrentPage] = useState(1);
  const [shouldHideLoadMore, setShouldHideLoadMore] = useState(true);

  const enquiry = searchParams.get("ctx") === "enquiry";
  const changeCoach = searchParams.get("ctx") === "change";
  const teamViewingFromHomepage = searchParams.get("ctx") === "team";

  const updateQuery = (q: string) => setSearchParams(q);

  const day = query?.date
    ? DateTime.fromFormat(query?.date, "yyyyMMdd").set({
        hour: Number(query.time),
        minute: 0,
        second: 0,
        millisecond: 0,
      })
    : DateTime.now().set({ minute: 0, second: 0, millisecond: 0 });

  const whereVariables = {
    where: {
      published: {
        equals: true,
      },
      ...(user?.currentUser?.role === UserRole.Individual || !user?.currentUser
        ? { excludeIndividuals: { not: { equals: true } } }
        : {}),
      ...filters,
      ...(searchQuery && { displayName: { contains: String(searchQuery) } }),
    },
  };

  const coachesOnPage = usePageCount(currentPage, COACHES_PER_PAGE);

  const availability = query.date
    ? {
        startsAt: day.toISO(),
        endsAt: day.plus({ hours: 1 }).toISO(),
      }
    : {
        startsAt: day.toISO(),
        endsAt: day.plus({ weeks: 12 }).toISO(),
      };

  const updateUserPackage = useUpdateUserPackageMutation();
  const searchCoachesByAvailability = useSearchCoachesByAvailabilityQuery(
    {
      ...whereVariables,
      availability,
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );
  const onUpdateFilters = () => {
    const updatedFilters = getWhereCoachQuery(qs.parse(location.search));

    setFilters(updatedFilters);
    searchCoachesByAvailability.refetch();
  };

  useEffect(() => {
    onUpdateFilters();
  }, [searchQuery, searchParams, searchCoachesByAvailability.data, setCurrentPage]);

  const onSearch = debounce(400, (search: string) => {
    updateQuery(
      qs.stringify({
        ...query,
        search,
      }),
    );

    Mixpanel.track("Coach Searched", {
      search,
    });

    return searchCoachesByAvailability.refetch();
  });

  const onChangeFilter = (filter: string) => (value: string) => {
    Mixpanel.track("Coach Filter Changed", {
      filter,
      value,
    });

    return updateQuery(
      qs.stringify({
        ...query,
        search: searchQuery,
        [filter]: value,
      }),
    );
  };

  const getSelectedFilters = (filterType: string) => {
    if (!filters[filterType]) return;

    const selectedFilters = filters[filterType];

    return selectedFilters?.some?.value?.in;
  };

  const onResetFilters = () => {
    if (enquiry) {
      setSearchParams({ ctx: "enquiry", eid: searchParams.get("eid") });
    } else if (changeCoach) {
      setSearchParams({ ctx: "change" });
    } else if (teamViewingFromHomepage) {
      setSearchParams({ ctx: "team" });
    } else {
      setSearchParams({});
    }
    setFilters({});
    setSearchQuery("");
    setCurrentPage(1);
    getSelectedFilters("approaches");
    window.location.reload();
  };

  useEffect(() => {
    const hide =
      coachesOnPage >=
      searchCoachesByAvailability?.data?.searchCoachesByAvailability?.filter((coach) =>
        query.date ? coach.events.length > 0 : true,
      ).length;
    setShouldHideLoadMore(hide);
  }, [coachesOnPage, searchCoachesByAvailability?.isLoading]);

  return (
    <Layout>
      {teamViewingFromHomepage && <StickyBanner />}
      <HtmlMeta title="Coaches" />

      <Layout.Content className="site-layout-content">
        <div className="flex flex-col text-center justify-center mt-10 px-4">
          <h1 className="text-4xl font-semibold">Find a coach</h1>

          {enquiry && (
            <h2 className="text-xl mb-4">
              Your preferences have been carried over from your programme quiz answers, you can edit
              these using the filters below.
            </h2>
          )}

          {!query.date ? (
            <Row gutter={12} align={"middle"}>
              <Col xs={24} lg={6} className="filter-col">
                <CategoriesFilter
                  onChange={onChangeFilter("categories")}
                  selected={getSelectedFilters("categories")}
                />
              </Col>
              <Col xs={24} lg={6} className="filter-col">
                <AudienceExperienceFilter
                  onChange={onChangeFilter("audienceExperiences")}
                  selected={getSelectedFilters("audienceExperiences")}
                />
              </Col>
              <Col xs={24} lg={6} className="filter-col">
                <SpecialismsFilter
                  onChange={onChangeFilter("specialisms")}
                  selected={getSelectedFilters("specialisms")}
                />
              </Col>
              <Col xs={24} lg={6} className="filter-col">
                <ApproachesFilter
                  onChange={onChangeFilter("approaches")}
                  selected={getSelectedFilters("approaches")}
                />
              </Col>

              <Col span={24} sm={18} md={20}>
                <Input.Search
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                    onSearch(e.target.value);
                  }}
                  placeholder="Search for coaches"
                  inputMode="search"
                  value={searchQuery ?? ""}
                  id="coaches-search"
                />
              </Col>
              <Col span={24} sm={6} md={4} className="filter-col m-auto">
                <Button tertiary className="w-full" onClick={onResetFilters}>
                  Reset all filters
                </Button>
              </Col>
            </Row>
          ) : (
            <div>
              <Button
                leftIcon={<ArrowLeft size={18} />}
                onClick={() => navigate(ROUTE_PATHS.SESSIONS.COACHES_BY_TIME)}
                primary
                large
              >
                Change your search
              </Button>
            </div>
          )}
        </div>
        <div className={styles["coach-results-container"]}>
          {searchCoachesByAvailability.isInitialLoading ? (
            <div className="mt-4">
              <LoadingSpinner />
            </div>
          ) : (
            <div>
              {searchCoachesByAvailability.data?.searchCoachesByAvailability
                ?.filter((coach) => (query.date ? coach.events.length > 0 : true))
                .slice(0, coachesOnPage)
                .map((coach) => (
                  <CoachCard
                    key={coach?.handle!}
                    coach={coach}
                    buttons={[
                      {
                        icon: <ArrowLeft size={16} />,
                        text: "Select this coach",
                        onClick: () => {
                          Mixpanel.track("Enquiry: Select this coach");
                          const _params = `${searchParams.toString()}`;
                          const coachUrl = `/enquiry/booking/${coach.handle}?${_params}`;
                          navigate(coachUrl);
                        },
                        primary: true,
                        hide: !enquiry,
                      },
                      {
                        icon: <ArrowLeft size={16} />,
                        text: "Select this coach",
                        onClick: async () => {
                          Mixpanel.track("Change Coach: Select this coach");
                          await updateUserPackage.mutateAsync({
                            where: { id: user.currentUser.activePackage.id },
                            data: { coach: { connect: { id: coach.id } } },
                          });

                          await user.invalidate();
                          setTimeout(() => {
                            navigate("/");
                          }, 1000);
                        },
                        primary: true,
                        hide: !changeCoach,
                      },
                      {
                        icon: <CalendarOutlined />,
                        text: query.date ? "Book session" : "See availability & book",
                        onClick: () => {
                          Mixpanel.track("Book session");
                          if (query?.date) {
                            navigate(
                              getConfirmUrl({
                                loggedIn: !!user.currentUser?.id,
                                handle: coach.handle,
                                date: day,
                                time: query?.time,
                              }),
                            );
                          }
                          navigate(`/coach/${coach.handle}`);
                        },
                        tertiary: true,
                        hide: enquiry || changeCoach || teamViewingFromHomepage,
                      },
                    ]}
                  />
                ))}
              <div className={styles["coach-results-pagination"]}>
                {searchCoachesByAvailability.isLoading ? (
                  <LoadingSpinner />
                ) : (
                  <Button
                    tertiary
                    large
                    className="w-full"
                    onClick={() => {
                      setCurrentPage(currentPage + 1);
                    }}
                    hidden={shouldHideLoadMore}
                    isSubmitting={searchCoachesByAvailability.isFetching}
                  >
                    Show 4 more coaches
                  </Button>
                )}
              </div>
              {query.date && (
                <div className="mt-20 flex flex-col items-center text-center md:w-[60%] mx-4 md:mx-auto gap-y-8">
                  <img src="/images/user_tools/appraisals.svg" alt="img" />
                  <div>
                    <h2 className="text-4xl">We have 100+ coaches waiting for you</h2>
                    <p className="text-lg">
                      If none of these coaches are right for you, that&apos;s okay! You can either
                      go back and change your search, or you can browse all our coaches.
                    </p>
                  </div>
                  <div className="flex flex-col md:flex-row gap-4">
                    <Button
                      leftIcon={<ArrowLeft size={16} />}
                      large
                      className="bg-bottleGreen border-bottleGreen border-solid text-white w-full md:w-fit"
                      onClick={() => navigate(ROUTE_PATHS.SESSIONS.COACHES_BY_TIME)}
                    >
                      Change your search
                    </Button>
                    <a href={ROUTE_PATHS.SESSIONS.COACHES}>
                      <Button
                        secondary
                        large
                        className="border-bottleGreen text-bottleGreen w-full md:w-fit"
                      >
                        Browse all coaches
                      </Button>
                    </a>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </Layout.Content>
    </Layout>
  );
};
