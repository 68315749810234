import { Button } from "$/components/Button";
import { useUser } from "$/state/user";
import { Mixpanel } from "$/tracking";
import { ArrowRight } from "lucide-react";
import { Modal } from "$/components/Modal";
import { useIsMobile } from "$/hooks";
import { useState } from "react";

export const StarterChoiceCard = () => {
  const user = useUser();
  const isMobile = useIsMobile();
  const [showThanksModal, setShowThanksModal] = useState(false);

  return (
    <div className="flex flex-col bg-white p-4 rounded-xl w-full md:w-1/2 lg:w-1/3">
      <div className="flex flex-col items-center text-center gap-x-4 pt-2">
        <h3 className="text-2xl font-semibold">Exploration Session</h3>
        <img src={"/images/Tools.svg"} alt={"Head"} className="w-2/3" />
        <p className="m-0 pt-2">
          Not exactly sure what coaching is, or what to bring to a session? Try this one off 45
          minute session with a coach, where you can discover what you want to work on through
          coaching.
        </p>
      </div>
      <div className="flex justify-center pt-2">
        <Button
          primary
          onClick={() => {
            setShowThanksModal(true);
            Mixpanel.track("Book an Exploration Session clicked", {
              from: "Book Coaching",
              user: user.currentUser,
            });
          }}
          rightIcon={<ArrowRight size={18} />}
        >
          Book an Exploration Session
        </Button>
      </div>

      {showThanksModal && (
        <Modal
          onClose={() => {
            setShowThanksModal(false);
          }}
          size={isMobile ? "lg" : "xl"}
        >
          <div className="flex flex-col m-auto p-4 items-center text-center gap-4">
            <h3 className="text-3xl">This feature is coming soon.</h3>
            <h4 className="text-lg">
              Thank you for letting us know you are interested in this feature, we will be looking
              to add this in the near future.
            </h4>
            <h4 className="text-lg">
              For now, we recommend trying a single session, where you can explore what you want to
              work on through coaching.
            </h4>
            <h4 className="text-lg">
              Once you have an idea of what you want to work on, you can start a programme focusing
              on that topic!
            </h4>
            <h4 className="text-lg m-0">
              We&apos;re continuously working to make our coaching options as useful as possible for
              users.
            </h4>
            <h4 className="text-lg m-0">
              If you have any questions, reach out to us at{" "}
              <a href={"mailto:hey@morehappi.com"} target="_blank" rel="noreferrer">
                hey@morehappi.com
              </a>
              .
            </h4>
          </div>
        </Modal>
      )}
    </div>
  );
};
