import {
  ListSessionsQuery,
  OrderByArg,
  SessionState,
  useCountSessionsQuery,
  useGetLastSessionQuery,
  useListSessionsQuery,
} from "$/graphql/types.generated";
import { useIsMobile } from "$/hooks";
import { Calendar, CirclePlus, Video, XCircle } from "lucide-react";
import { DateTime } from "luxon";
import { Button } from "../Button";
import { BookColor, Heart, MapPin } from "../Icons";
import { SessionCardProps } from "./CoacheeSessionCard";
import { useGetProgrammeQuizQuery } from "$/graphql/storyblok/types.generated";
import { google } from "calendar-link";
import { useUser } from "$/state/user";
import { Mixpanel } from "$/tracking";

export type Session = NonNullable<NonNullable<ListSessionsQuery["listSessions"]>[number]>;

interface CoachSessionCardProps extends SessionCardProps {}

const coachingExposureMapper = {
  new: "This is their first experience of coaching",
  entry: "They have had a few coaching sessions before",
  veteran: "They are a coaching veteran",
  coach: "They are a trained coach themselves",
};

const jobLevelMapper = {
  intern: "Intern",
  entry: "Entry level",
  associate: "Associate",
  senior: "Mid - Senior level",
  director: "Director / Team Lead",
  executive: "Executive",
};

const getGoogleCalendarLink = (session: Session) => {
  let programmeTagline = "Ad-hoc coaching session";

  if (session.userPackage?.plan) {
    programmeTagline = "Coaching programme session";
  }

  const details = `${programmeTagline}
  <br/>
<b>Please use the following link if you need to cancel:<b/>

<a href="https://app.morehappi.com/sessions/${session.id}/cancel/coach">Cancel session</a>`;

  return google({
    title: `More Happi coaching call with ${session?.teamMember?.user?.firstName} ${session?.teamMember?.user?.lastName}`,
    start: DateTime.fromISO(session.startsAt).toISO(),
    end: DateTime.fromISO(session.endsAt).toISO(),
    location: session.location ?? "",
    description: details,
  });
};

export const CoachSessionCard = ({ session, timescale, hideActions }: CoachSessionCardProps) => {
  const isMobile = useIsMobile();
  const user = useUser();
  const isFuture = timescale === "future";
  const isWork = session?.categories?.some(({ category }) => category.label === "work");
  const teamMemberDisplayName = `${session?.teamMember?.user?.firstName} ${session?.teamMember?.user?.lastName}`;
  const programmeQuiz = useGetProgrammeQuizQuery(
    { id: `programmes/${session.userPackage?.plan}` },
    { enabled: !!session.userPackage?.plan },
  );

  const isRecent =
    DateTime.now() >= DateTime.fromISO(session.startsAt).minus({ hours: 12 }) &&
    DateTime.now() <= DateTime.fromISO(session.startsAt).plus({ hours: 2 });

  const { data: lastSession } = useGetLastSessionQuery({
    where: {
      coachId: { equals: session.coach?.id },
      startsAt: { lt: session.startsAt },
      teamMember: {
        userId: {
          equals: session.teamMember?.user?.id,
        },
      },
    },
  });

  const lastSessionText = lastSession?.listSessions[0]?.startsAt
    ? DateTime.fromISO(lastSession?.listSessions[0]?.startsAt).toFormat("dd LLL yyyy")
    : "N/A";

  const { data: totalSessionCountWithCoach } = useCountSessionsQuery({
    where: {
      coachId: { equals: session.coach?.id },
      startsAt: { lt: session.startsAt },
      state: { in: [SessionState.Confirmed, SessionState.ConfirmedCoach] },
      teamMember: {
        userId: {
          equals: session.teamMember?.user?.id,
        },
      },
    },
  });

  const { data: nextSessionWithCoachee } = useListSessionsQuery({
    where: {
      coachId: { equals: session.coach?.id },
      startsAt: { gt: session.startsAt },
      state: { in: [SessionState.Confirmed, SessionState.ConfirmedCoach] },
      teamMember: {
        userId: {
          equals: session.teamMember?.user?.id,
        },
      },
    },
    take: 1,
    orderBy: { startsAt: OrderByArg.Asc },
  });

  const { data: totalSessionCount } = useCountSessionsQuery({
    where: {
      startsAt: { lt: session.startsAt },
      state: { in: [SessionState.Confirmed, SessionState.ConfirmedCoach] },
      teamMember: {
        userId: {
          equals: session.teamMember?.user?.id,
        },
      },
    },
  });

  const { data: totalSessionCountProgramme } = useCountSessionsQuery({
    where: {
      startsAt: { lte: session.startsAt },
      state: { in: [SessionState.Confirmed, SessionState.ConfirmedCoach] },
      userPackageId: { equals: session.userPackage?.id },
    },
  });

  return (
    <div className="max-w-[600px]">
      <div className="flex flex-col border border-solid border-bottleGreen rounded-xl">
        <div className="border-0 border-b border-solid border-bottleGreen flex flex-wrap">
          <section className="w-full md:w-[27.5%] border-0 md:border-r border-b md:border-b-0 border-solid border-bottleGreen py-2 px-4 flex justify-center rounded-tl-xl">
            <div className="flex flex-col md:m-auto">
              <div className="text-lg">{DateTime.fromISO(session.startsAt).toFormat("ccc")}</div>
              <div className="text-5xl font-bold">
                {DateTime.fromISO(session.startsAt).toFormat("dd")}
                <span className="text-lg font-normal ml-1">
                  {DateTime.fromISO(session.startsAt).toFormat("MMM")}
                </span>
              </div>
            </div>
          </section>
          <section className="w-full md:w-[27.5%] border-0 md:border-r border-solid border-bottleGreen py-2 px-4 flex justify-center">
            <div className="flex flex-col text-center md:w-full md:m-auto">
              <div className="flex justify-between text-lg md:w-full">
                <p className="m-0">{DateTime.fromISO(session.startsAt).toFormat("a")}</p>
                <p className="m-0">
                  GMT
                  {DateTime.fromISO(session.startsAt).offset !== 0 &&
                    DateTime.fromISO(session.startsAt).toFormat("Z")}
                </p>
              </div>
              <div className="text-5xl font-bold">
                {DateTime.fromISO(session.startsAt).toFormat("h:mm")}
              </div>
            </div>
          </section>
          <section className="basis-full md:basis-[45%] text-lg">
            <div className="border-0 border-t md:border-t-0 border-b border-solid border-bottleGreen h-1/2 py-2 px-6 flex items-center gap-x-1 justify-center md:justify-start">
              {session?.userPackage?.plan ? (
                <div className="h-1/2 flex m-auto items-center gap-x-2 justify-center md:justify-start text-black">
                  <p className="h-1/2 flex m-auto items-center">
                    <BookColor />
                  </p>

                  <p className="h-1/2 flex m-auto items-center ">
                    Programme session{" "}
                    {totalSessionCountProgramme?.countSessions > 0 &&
                      `${totalSessionCountProgramme?.countSessions} / ${session.userPackage.sessionCount}`}
                  </p>
                </div>
              ) : (
                <p className="h-1/2 flex m-auto items-center gap-x-2 justify-center md:justify-start text-black">
                  {isWork ? <BookColor /> : <Heart color="red" fill="red" />}
                  {isWork ? "Work" : "Personal"} coaching session
                </p>
              )}
            </div>
            {session.cancelledAt ? (
              <div className="flex w-full h-[50%] justify-center items-center bg-[#d53c3c]">
                Session cancelled
              </div>
            ) : isFuture ? (
              <a
                className="h-1/2 py-2 px-6 flex items-center gap-x-2 justify-center md:justify-start text-black"
                href={!session.externalId ? session.location ?? "#" : "#"}
                target="_blank"
                rel="noreferrer"
              >
                <MapPin fill="#006A39" size={14} /> Online via Google Meet
              </a>
            ) : (
              <div className="flex w-full h-[50%] justify-center items-center bg-[#006A39] text-white">
                Session complete
              </div>
            )}
          </section>
        </div>
        <div className="w-full flex bg-white">
          <section className="w-1/3 h-auto rounded-bl-xl flex justify-center">
            <img
              className="object-contain w-[100%]"
              src="/images/Book_a_session.svg"
              alt={"Coach Session"}
            />
          </section>
          <section className="w-full md:w-[70%] bg-white p-2 md:px-4 md:py-4 flex flex-col gap-y-2 justify-between">
            <div>
              <h3 className="text-xl md:text-2xl mb-0">{teamMemberDisplayName}</h3>
              {session.teamMember.user.jobLevel ? (
                <h4 className="text-sm md:text-lg">
                  {jobLevelMapper[session.teamMember.user.jobLevel]}
                  {session.teamMember.isManager ? " Manager" : " Team Member"} at{" "}
                  {session?.teamMember?.company?.name.split("(")[0]}
                </h4>
              ) : (
                <h4 className="text-sm md:text-lg">
                  {session.teamMember.isManager ? "Manager" : "Works"} at{" "}
                  {session?.teamMember?.company?.name.split("(")[0]}
                </h4>
              )}

              {session?.userPackage?.plan && programmeQuiz.isFetched && (
                <h5>
                  Programme:{" "}
                  {programmeQuiz.data.PageItem.content.docsPage ? (
                    <a
                      href={programmeQuiz.data.PageItem.content.docsPage}
                      target="_blank"
                      rel="noreferrer"
                      className="text-midnight underline hover:no-underline"
                    >
                      <span className="font-semibold">
                        {programmeQuiz.data.PageItem.content.displayName}
                      </span>
                    </a>
                  ) : (
                    <span className="font-semibold">
                      {programmeQuiz.data.PageItem.content.displayName}
                    </span>
                  )}{" "}
                  ({session?.userPackage?.sessionCount} sessions)
                </h5>
              )}
              {session.packages.length > 0 && (
                <h5>Topic: {session.packages.map((p) => p.package.label).join(", ")}</h5>
              )}
              {session.teamMember.user.coachingExposure && (
                <h5>
                  Coaching Exposure:{" "}
                  {coachingExposureMapper[session.teamMember.user.coachingExposure]}
                </h5>
              )}
              {totalSessionCount?.countSessions === 0 ? (
                <h5 className="!bg-pink p-2 rounded-lg">
                  This is the users first session with More Happi.
                </h5>
              ) : (
                <>
                  {totalSessionCount?.countSessions > 0 &&
                    totalSessionCount?.countSessions !==
                      totalSessionCountWithCoach?.countSessions && (
                      <h5>Previous sessions with More Happi: {totalSessionCount?.countSessions}</h5>
                    )}
                  <h5>Previous sessions with you: {totalSessionCountWithCoach?.countSessions} </h5>
                  <h5>Last session with you before this one: {lastSessionText}</h5>
                </>
              )}
              {isFuture &&
                (nextSessionWithCoachee?.listSessions.length > 0 ? (
                  <h5>
                    Next session with you after this one:{" "}
                    {DateTime.fromISO(nextSessionWithCoachee.listSessions[0].startsAt).toFormat(
                      "h:mma dd MMM",
                    )}
                  </h5>
                ) : (
                  <h5 className="!bg-orange p-2 rounded-lg">
                    No further sessions scheduled - remember to offer rebooking
                  </h5>
                ))}
            </div>
          </section>
        </div>
        <div className="bg-white rounded-b-xl px-4 pb-4 flex justify-end">
          {!hideActions && (
            <div className="flex flex-wrap gap-2 md:gap-y-0 justify-around">
              {isFuture && !session.cancelledAt && !session.externalId && (
                <a href={getGoogleCalendarLink(session)} target="_blank" rel="noreferrer">
                  {" "}
                  <Button
                    tertiary
                    small={isMobile}
                    large={!isMobile}
                    className="text-sm md:text-base"
                  >
                    <Calendar size="1.125rem" /> Add to calendar
                  </Button>
                </a>
              )}
              {isFuture && !session.cancelledAt && !session.externalId && (
                <a
                  href={!session.externalId ? session.location ?? "#" : "#"}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button
                    tertiarySolid
                    small={isMobile}
                    large={!isMobile}
                    className="text-sm md:text-base"
                  >
                    <Video size="1.125rem" /> Join Google Meet
                  </Button>
                </a>
              )}
              {isRecent && !session.cancelledAt && (
                <a href={`/book-next?sid=${session.id}`}>
                  <Button
                    primary
                    small={isMobile}
                    large={!isMobile}
                    className="text-sm md:text-base"
                    onClick={() =>
                      Mixpanel.track("Coach Booking: Started", {
                        coach: user.currentUser.coach.id,
                        coachee: session.teamMember.user.id,
                      })
                    }
                  >
                    <CirclePlus size="1.125rem" />
                    Book a future session
                  </Button>
                </a>
              )}
              {!isFuture && user?.currentUser.id === "205c5664-c514-478a-b3f8-e0ff35aa651c" && (
                <a href={`/book-next?sid=${session.id}`}>
                  <Button
                    primary
                    small={isMobile}
                    large={!isMobile}
                    className="text-sm md:text-base"
                    onClick={() =>
                      Mixpanel.track("Tor Coach Booking: Started", {
                        coach: user.currentUser.coach.id,
                        coachee: session.teamMember.user.id,
                      })
                    }
                  >
                    <CirclePlus size="1.125rem" />
                    Book a future session
                  </Button>
                </a>
              )}
            </div>
          )}
        </div>
      </div>
      {isFuture && !session.cancelledAt && !session.externalId && (
        <div className="flex justify-end gap-x-4 px-2 py-1 text-lg">
          <a
            className="flex items-center gap-x-1 underline"
            href={`/sessions/${session.id}/cancel/coach`}
            target="_blank"
            rel="noreferrer"
          >
            <XCircle size="1.125rem" color="red" /> Cancel session
          </a>
        </div>
      )}
    </div>
  );
};
