import { useEffect, useState } from "react";
import { useUser } from "$/state/user";
import {
  OrderByArg,
  Session,
  SessionState,
  useCancelSessionMutation,
  useCancelUserPackageMutation,
  useListSessionsQuery,
} from "$/graphql/types.generated";
import { addMinutes, startOfHour } from "date-fns";
import { CoacheeSessionCard } from "$/components/SessionCard/CoacheeSessionCard";
import { LoadingSpinner } from "$/components/LoadingSpinner";
import { Modal } from "$/components/Modal";
import { Button } from "$/components/Button";
import { ArrowRight, X, XCircle } from "lucide-react";
import { CoachSessionCard } from "$/components/SessionCard/CoachSessionCard";
import { CoachToolCard } from "./components/CoachToolCard";
import { CoachStats } from "./components/coachStats";
import { SchedulerProvider } from "$/pages/Sessions/Scheduler/schedulerContext";
import { Schedule } from "$/pages/Sessions/Coach";
import { app } from "$/configs/app.config";
import { DateTime } from "luxon";
import { useGetProgrammeQuizQuery } from "$/graphql/storyblok/types.generated";
import { useNavigate } from "react-router";

export const CoachDashboard = () => {
  const user = useUser();
  const navigate = useNavigate();
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const [endPackageModalOpen, setEndPackageModalOpen] = useState(false);
  const [showScheduler, setShowScheduler] = useState<boolean>(false);

  const { data: futureCoachSession, isLoading } = useListSessionsQuery({
    where: {
      coachId: { equals: user.currentUser?.coach?.id },
      startsAt: { gte: addMinutes(startOfHour(new Date()), -1) },
      state: { in: [SessionState.Confirmed, SessionState.ConfirmedCoach] },
    },
    orderBy: [{ startsAt: OrderByArg.Asc }],
    take: 1,
  });

  const { data: futureCoacheeSession } = useListSessionsQuery({
    where: {
      teamMemberId: { equals: user.currentUser?.teamMember?.id },
      startsAt: { gte: addMinutes(startOfHour(new Date()), -1) },
      state: { in: [SessionState.Confirmed, SessionState.ConfirmedCoach] },
    },
    orderBy: [{ startsAt: OrderByArg.Asc }],
    take: 1,
  });

  useEffect(() => {
    const seenWelcomeModal = localStorage.getItem("seenCoachDashboardWelcomeModal");
    if (seenWelcomeModal !== "3") {
      setShowWelcomeModal(true);
    }
  }, []);

  const currentPackage = user?.currentUser?.activePackage;
  const coachFirstName = currentPackage?.coach?.displayName.split(" ")[0];
  const nextCoachSession = futureCoachSession?.listSessions[0] || null;
  const nextCoacheeSession = futureCoacheeSession?.listSessions[0] || null;
  const programmeQuiz = useGetProgrammeQuizQuery({ id: `programmes/${currentPackage?.plan}` });
  const cancel = useCancelSessionMutation();
  const cancelProgramme = useCancelUserPackageMutation();

  return (
    <div className="max-w-[1240px] mx-auto">
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <div className="flex flex-col w-full">
            <div className="w-full flex flex-col mt-4 computer:flex-row">
              <div className="w-full px-4 flex flex-col computer:m-0 computer:w-1/2 computer:px-0 ">
                <h2 className="w-full text-center text-3xl mb-2">
                  Your{" "}
                  {new Date(nextCoachSession?.startsAt) > startOfHour(new Date())
                    ? "next"
                    : "current"}{" "}
                  session as a coach
                </h2>
                <div className="relative computer:w-[1/2] max-w-[600px] mx-auto computer:mx-4">
                  {nextCoachSession ? (
                    <CoachSessionCard session={nextCoachSession as Session} timescale="future" />
                  ) : (
                    <div className="w-full computer:max-w-1/2 flex flex-col m-auto h-full">
                      <div className="w-full flex flex-col bg-lightGreen rounded-t-xl px-1">
                        <div className="w-full flex justify-center">
                          <img
                            className="w-1/4 mb-[-6px]"
                            src="/images/Book_a_session.svg"
                            alt="coaching"
                          />
                        </div>
                      </div>
                      <div className="w-full flex flex-col justify-between bg-white rounded-b-xl px-4 h-full">
                        <div className="w-full m-2 flex flex-col justify-center">
                          <h3 className="text-md m-1">
                            You don&apos;t have any future sessions as coach.
                          </h3>
                          <h4 className="text-sm m-1">
                            To maximise your potential for bookings, ensure your Google Calendar
                            availability is updated for at least the next six weeks.
                          </h4>
                          <h4 className="text-sm m-1 mb-0">
                            Note: You can boost booking availability AND cap your weekly sessions to
                            avoid a crowded diary.
                          </h4>
                        </div>
                        <div className="flex justify-end pb-4">
                          <a
                            href={
                              "https://www.notion.so/The-Coach-Handbook-73a5e138483447acb937ad00dadbe3c5?p=307af46393774afd92967d88de92c5e9&pm=c"
                            }
                          >
                            <Button tertiarySolid rightIcon={<ArrowRight size={18} />}>
                              Availability explained
                            </Button>
                          </a>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="w-full px-4 flex flex-col mt-4 computer:m-0 computer:w-1/2 computer:px-0">
                <h2 className="w-full text-center text-3xl mb-2">
                  Your{" "}
                  {new Date(nextCoacheeSession?.startsAt) > startOfHour(new Date())
                    ? "next"
                    : "current"}{" "}
                  session as a coachee
                </h2>
                <div className="relative computer:w-[1/2] max-w-[600px] mx-auto computer:mx-4">
                  {currentPackage ? (
                    nextCoacheeSession ? (
                      <CoacheeSessionCard
                        session={nextCoacheeSession as Session}
                        timescale="future"
                        onPlaceAnother={() => setShowScheduler(true)}
                      />
                    ) : (
                      <div className="w-full max-w-[600px] flex flex-col justify-center m-auto h-full">
                        <div className="w-full flex flex-col bg-peach rounded-t-xl px-1">
                          <div className="w-full flex justify-center">
                            <img className="w-1/4 mb-[-6px]" src="/images/Huddle.svg" alt="group" />
                          </div>
                        </div>
                        <div className="w-full flex flex-col justify-between bg-white rounded-b-xl px-4 h-full">
                          <div className="w-full m-2 flex flex-col justify-center">
                            <h3 className="text-md m-1">
                              You are currently on the{" "}
                              {programmeQuiz?.data?.PageItem?.content?.docsPage ? (
                                <a
                                  href={programmeQuiz.data.PageItem.content.docsPage}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="text-midnight underline hover:no-underline"
                                >
                                  <span className="font-semibold">
                                    {programmeQuiz.data.PageItem.content.displayName}
                                  </span>
                                </a>
                              ) : (
                                <span className="font-semibold">
                                  {programmeQuiz.data.PageItem.content.displayName}
                                </span>
                              )}{" "}
                              programme.
                            </h3>
                            <h3 className="text-base m-1">
                              You&apos;re on a programme, but have no future sessions booked.
                            </h3>
                            <h3 className="text-sm m-1">
                              Your programme ends on{" "}
                              {DateTime.fromISO(currentPackage.endDate).toFormat("dd/MM/yyyy")}. You
                              have booked {currentPackage.validSessions.length} out of{" "}
                              {currentPackage?.sessionCount} sessions.
                            </h3>
                            <h4 className="text-sm m-1 pb-2">
                              You can use coaching to develop your business, learn styles and
                              techniques from others, map out building your personal brand or
                              anything else!
                            </h4>
                          </div>
                          <div className="flex justify-end pb-4">
                            <Button
                              primary
                              rightIcon={<ArrowRight size={18} />}
                              onClick={() => setShowScheduler(true)}
                            >
                              Book next session
                            </Button>
                          </div>
                        </div>
                      </div>
                    )
                  ) : nextCoacheeSession ? (
                    <CoacheeSessionCard
                      session={nextCoacheeSession as Session}
                      timescale="future"
                      onPlaceAnother={() => setShowScheduler(true)}
                    />
                  ) : (
                    <div className="w-full max-w-[600px] flex flex-col justify-center m-auto h-full">
                      <div className="w-full flex flex-col bg-peach rounded-t-xl px-1">
                        <div className="w-full flex justify-center">
                          <img className="w-1/4 mb-[-6px]" src="/images/Huddle.svg" alt="group" />
                        </div>
                      </div>
                      <div className="w-full flex flex-col justify-between bg-white rounded-b-xl px-4 h-full">
                        <div className="w-full m-2 flex flex-col justify-center">
                          <h3 className="text-md m-1">
                            You don&apos;t have any future sessions as coachee.
                          </h3>
                          <h4 className="text-md m-1">
                            Did you know you also get access to More Happi coaching?
                          </h4>
                          <h4 className="text-sm m-1 pb-2">
                            You can use coaching to develop your business, learn styles and
                            techniques from others, map out building your personal brand or anything
                            else!
                          </h4>
                        </div>
                        <div className="flex justify-end pb-4">
                          <a href={"/book-coaching"}>
                            <Button primary rightIcon={<ArrowRight size={18} />}>
                              Book coaching
                            </Button>
                          </a>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {currentPackage && (
                  <div className="mx-auto w-full max-w-[600px] flex justify-end">
                    <div
                      className="flex items-center gap-x-1 pr-2 underline text-lg text-textGrey cursor-pointer computer:pr-0"
                      onClick={async () => {
                        setEndPackageModalOpen(true);
                      }}
                    >
                      <XCircle size="1.125rem" color="red" /> Cancel programme
                    </div>
                  </div>
                )}
              </div>
            </div>

            {showScheduler && (
              <div className="bg-white my-10 p-8 rounded-3xl">
                <Button tertiary leftIcon={<X />} onClick={() => setShowScheduler(false)}>
                  Close
                </Button>
                {!currentPackage.hitSessionLimit && (
                  <>
                    <h1 className="font-semibold text-center max-w-[500px] mx-auto scroll">
                      Book your{" "}
                      {currentPackage.validSessions.length > 0 ? "next session" : "first session"}{" "}
                      with {coachFirstName} now ({(currentPackage?.validSessions?.length ?? 0) + 1}/
                      {currentPackage?.sessionCount})
                    </h1>
                    <div className="flex items-center justify-center gap-4 mt-20">
                      <img
                        src={`${app.CLOUDFRONT.USER_ASSETS}/${currentPackage.coachId}/thumbnail.jpg`}
                        alt="profile"
                        className="w-12 h-12 rounded-full"
                      />
                      <h3 className="font-semibold text-2xl m-0">
                        {coachFirstName}&apos;s availability
                      </h3>
                    </div>
                    <SchedulerProvider>
                      <Schedule
                        coachId={currentPackage.coachId}
                        coachHandle={currentPackage.coach.handle}
                        postBookingUrl="dashboard"
                        context={{
                          package: currentPackage.id,
                        }}
                        lastDate={currentPackage.endDate}
                      />
                    </SchedulerProvider>
                  </>
                )}

                {currentPackage.hitSessionLimit && (
                  <div className="flex flex-col items-center">
                    <h1 className="font-semibold text-center mx-auto mt-8">
                      You have booked all the sessions for this plan
                    </h1>
                    <p className="text-center text-lg">
                      We only allow you to have one plan active at any time. You will be able to
                      start a new plan when your current one is over.
                    </p>
                    <p className="text-center text-lg">
                      You can book an ad-hoc session at any time.
                    </p>
                    <a href="/coaches" className="">
                      <Button primary>Book an ad-hoc session</Button>
                    </a>
                  </div>
                )}
              </div>
            )}

            <div className="flex mt-4 justify-center px-4">
              <a href="/sessions" className="cursor-pointer text-balance hover:no-underline">
                <Button large tertiary rightIcon={<ArrowRight size={18} />}>
                  View my previous and upcoming sessions
                </Button>
              </a>
            </div>
          </div>
          <div className="flex flex-col mt-2">
            <h2 className="text-3xl pl-4">Coach tools</h2>
            <div className="w-full flex px-4 m-auto pb-4 gap-6 overflow-x-scroll computer:scrollbar-hide computer:justify-center computer:px-0">
              <CoachToolCard
                heading={"Coach Handbook"}
                subheading={"Have a question? This is your guide for coaching at More Happi"}
                imageString={"/images/Books.svg"}
                colour={"orange"}
                margin={8}
                link={"https://www.notion.so/The-Coach-Handbook-73a5e138483447acb937ad00dadbe3c5"}
              />
              <CoachToolCard
                heading={"Coach Rebooking"}
                subheading={
                  "Make sure you are up to speed for your next call with this new feature"
                }
                imageString={"/images/Listening.svg"}
                colour={"lightGreen"}
                margin={8}
                link={
                  "https://www.notion.so/Coach-Rebooking-New-Feature-114c2746959d8094abfccb97c8378a01"
                }
              />
              <CoachToolCard
                heading={"Update Availability"}
                subheading={"It's important to keep your availability up to date, learn how"}
                imageString={"/images/Painting.svg"}
                colour={"peach"}
                margin={6}
                link={
                  "https://www.notion.so/The-Coach-Handbook-73a5e138483447acb937ad00dadbe3c5?p=307af46393774afd92967d88de92c5e9&pm=c"
                }
              />
              <CoachToolCard
                heading={"Supervision Sessions"}
                subheading={"Book sessions to discuss your growth & challenges as a coach"}
                buttonText="Book now"
                imageString={"/images/Support_Guides.svg"}
                colour={"pink"}
                margin={5}
                link={"https://www.notion.so/Supervision-f1bf7975ae124e9ea1d0f713ec0f4f56"}
              />
              <CoachToolCard
                heading={"Happi Hour Training"}
                subheading={"Monthly training. Book your next session or watch past recordings"}
                buttonText="Book now"
                imageString={"/images/Tools.svg"}
                colour={"lightPurple"}
                margin={6}
                link={"https://www.notion.so/Happi-Hour-03a60e94338e483aa8c6ac0576d99add"}
              />
            </div>
          </div>
          <CoachStats />

          <div className="w-[95%] computer:w-3/4 flex flex-col bg-white rounded-xl m-auto mb-4 p-2 px-6 border-solid border-2 border-orange items-center text-center">
            <h4 className="text-lg">
              We&apos;re continuously working to make this dashboard as useful as possible for
              coaches.
            </h4>
            <h4 className="text-lg">
              If you have any ideas for improvements, reach out to us at{" "}
              <a href={"mailto:hey@morehappi.com"} target="_blank" rel="noreferrer">
                hey@morehappi.com
              </a>
              .
            </h4>
            <h4 className="text-lg">
              We value your feedback and are committed to enhancing your coaching experience.
            </h4>
          </div>
        </div>
      )}
      {endPackageModalOpen && (
        <Modal onClose={() => setEndPackageModalOpen(false)}>
          <h1 className="text-center mb-2">Are you sure you want to cancel your programme?</h1>
          <p className="text-center text-lg">
            This will cancel any future sessions you have booked on this programme.
          </p>
          <p className="text-center text-lg">You will still be able to book ad-hoc sessions.</p>
          <div className="flex justify-between gap-4 mt-8">
            <Button large primary onClick={() => setEndPackageModalOpen(false)}>
              Keep my programme
            </Button>
            <Button
              large
              error
              onClick={async () => {
                await cancelProgramme.mutateAsync({
                  id: currentPackage.id,
                });
                await Promise.all(
                  user.currentUser.activePackage.sessions
                    .filter(
                      (s) =>
                        s.state === (SessionState.Confirmed || SessionState.ConfirmedCoach) &&
                        DateTime.fromISO(s.startsAt) > DateTime.now(),
                    )
                    .map(async (s) => {
                      return cancel.mutateAsync({
                        sessionId: s!.id,
                        reason: "User has cancelled their programme",
                      });
                    }),
                );
                navigate(0);
              }}
            >
              Cancel my programme
            </Button>
          </div>
        </Modal>
      )}
      {showWelcomeModal && (
        <Modal
          size="lg"
          onClose={() => {
            setShowWelcomeModal(false);
            const seenWelcomeModal = localStorage.getItem("seenCoachDashboardWelcomeModal");
            if (seenWelcomeModal === "2") {
              localStorage.setItem("seenCoachDashboardWelcomeModal", "3");
            } else if (seenWelcomeModal === "1") {
              localStorage.setItem("seenCoachDashboardWelcomeModal", "2");
            } else {
              localStorage.setItem("seenCoachDashboardWelcomeModal", "1");
            }
          }}
        >
          <div className="flex flex-col m-auto p-4 items-center text-center gap-4">
            <h3 className="text-3xl">
              Welcome to your <i>new</i> Coach Dashboard!
            </h3>
            <h4 className="text-lg">
              Before every coaching session, please remember to log in here. This dashboard contains
              everything you need to coach effectively with More Happi.
            </h4>
            <h4 className="text-lg">
              From here you&apos;ll be able to:
              <ul className="flex flex-col pt-4 text-start gap-y-2">
                <li>See important details about your upcoming session</li>
                <li>Review your latest testimonials and stats</li>
                <li>Be able to book the next session for your coachee</li>
              </ul>
            </h4>
            <h4 className="text-lg m-0">
              We&apos;re continuously working to make this dashboard as useful as possible for
              coaches.
            </h4>
            <h4 className="text-lg m-0">
              If you have any ideas for improvements, reach out to us at{" "}
              <a href={"mailto:hey@morehappi.com"} target="_blank" rel="noreferrer">
                hey@morehappi.com
              </a>
              .
            </h4>
            <h4 className="text-lg m-0">
              We value your feedback and are committed to enhancing your coaching experience.
            </h4>
          </div>
        </Modal>
      )}
    </div>
  );
};
