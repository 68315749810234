import { Button } from "$/components/Button";
import Checkbox from "$/components/Checkbox";
import { useState } from "react";
import { Send } from "..";
import { useUser } from "$/state/user";
import { DateTime, Settings } from "luxon";
import timezones from "timezones-list";
import { useNavigate } from "react-router";
import { Modal } from "$/components/Modal";
import { TimezoneSelect } from "$/components/Select/Selectors/Timezone";
import { useIsMobile } from "$/hooks";

export const TimezoneModal = ({ timezone, subtitle }: { timezone: string; subtitle: string }) => {
  const user = useUser();
  const [isOpen, setIsOpen] = useState(false);
  const [tz, setTz] = useState(timezone ?? DateTime.now().toFormat("z"));
  const navigate = useNavigate();

  const close = () => {
    setIsOpen(false);
    setTz(timezone ?? DateTime.now().toFormat("z"));
  };

  return (
    <div>
      <div
        className="flex cursor-pointer items-center justify-center text-center flex-col md:flex-row md:gap-2"
        onClick={() => setIsOpen(true)}
      >
        <p className="text-lg m-0">{subtitle}</p>
        <p className="text-lg m-0">Your current timezone is:</p>
        <p className="text-lg m-0 underline">
          {timezones.find(({ tzCode }) => tzCode === timezone)?.label}
        </p>
      </div>
      {isOpen ? (
        <Modal onClose={close}>
          <TimezoneSelect value={tz} onChange={(newValue) => setTz(newValue)} />
          <div className="flex items-center justify-between">
            <Button error onClick={close} className="w-[48%]">
              Cancel
            </Button>
            <Button
              primary
              onClick={async () => {
                Settings.defaultZone = tz;
                localStorage.setItem("timezone", tz);
                if (user?.currentUser) {
                  await user.update({
                    timezone: tz,
                  });
                }
                navigate(0);
                setIsOpen(false);
              }}
              className="w-[48%]"
            >
              Save
            </Button>
          </div>
        </Modal>
      ) : null}
    </div>
  );
};

export const EnquirySelectDate = ({
  send,
  title,
  subtitle,
  options,
  next,
  value = [],
}: {
  send: Send;
  title: string;
  subtitle: string;
  options: Array<{ label: string; day: Array<{ label: string; value: string; state: string }> }>;
  next?: string;
  value?: Array<string>;
}) => {
  const [selected, setSelected] = useState("");
  const isMobile = useIsMobile();

  return (
    <div className="w-full">
      <h1 className="text-center text-2xl md:text-4xl font-semibold mb-2">{title}</h1>
      <TimezoneModal
        subtitle={subtitle}
        timezone={localStorage.getItem("timezone") || "Europe/London"}
      />
      <div className="w-full flex items-center justify-center pt-6 md:px-0">
        <div className="max-w-[430px] flex flex-col gap-4 items-start">
          <div className="flex w-[70%] max-w-[300px] justify-between self-end gap-2">
            <div className="flex flex-col m-0 p-0 text-center gap-0">
              <p className="font-bold m-0">Morning</p>
              <p>(Before 12pm)</p>
            </div>
            <div className="flex flex-col m-0 p-0 text-center gap-0">
              <p className="font-bold m-0">Afternoon</p>
              <p>(12pm - 6pm)</p>
            </div>
            <div className="flex flex-col m-0 p-0 text-center gap-0">
              <p className="font-bold m-0">Evening</p>
              <p>(After 6pm)</p>
            </div>
          </div>
          {options.map((daysArray) => (
            <div className="flex w-[100%] max-w-[400px] justify-between" key={daysArray.label}>
              <p className="font-bold w-20 m-0">{daysArray.label}</p>
              {daysArray.day.map((specificDay) => (
                <Checkbox
                  key={specificDay.label.replaceAll(" ", "-")}
                  checked={selected.includes(specificDay.value)}
                  controlled
                  onChange={(newState) => {
                    setSelected((prev) => {
                      const newValues = [...prev];
                      const id = specificDay.value;

                      if (newState.target.checked) {
                        return [...newValues, id];
                      } else {
                        return newValues.filter((item) => item !== id);
                      }
                    });
                  }}
                  className="text-left"
                  labelClass="text-lg"
                />
              ))}
            </div>
          ))}
          <Button
            tertiary
            className="w-full mt-4"
            disabled={selected.length > 0}
            onClick={() => {
              send({ next, value: "Flexible" });
              setSelected([]);
            }}
          >
            {isMobile
              ? "I'm flexible"
              : "I'm flexible, Show me my best matches regardless of availability"}
          </Button>
        </div>
      </div>
      <Button
        primary
        large
        className="w-full mt-8"
        disabled={selected.length === 0}
        onClick={() => {
          send({ next, value: selected });
          setSelected([]);
        }}
      >
        Continue
      </Button>
    </div>
  );
};
