import { ListSessionsQuery } from "$/graphql/types.generated";
import { ArrowRight } from "lucide-react";
import { Button } from "$/components/Button";
import cn from "classnames";

export type Session = NonNullable<NonNullable<ListSessionsQuery["listSessions"]>[number]>;

interface CoachToolCardProps {
  heading: string;
  subheading: string;
  imageString: string;
  colour: string;
  link: string;
  buttonText?: string;
  margin?: number;
}

export const CoachToolCard = ({
  heading,
  subheading,
  imageString,
  colour,
  link,
  buttonText = "Read more",
  margin,
}: CoachToolCardProps) => {
  return (
    <div className="w-56 min-w-56 flex-shrink-0 snap-start">
      <div className="bg-white rounded-t-xl">
        <div className="w-full flex justify-center pt-2">
          <img
            className={cn("w-2/3", {
              "mb-[-5px]": margin === 5,
              "mb-[-6px]": margin === 6,
              "mb-[-8px]": margin === 8,
            })}
            src={imageString}
            alt={heading}
          />
        </div>
      </div>
      <div
        className={cn(`w-full flex flex-col bg-${colour} rounded-b-xl px-1`, {
          "mb-[1px]": margin === 6,
          "mb-[3px]": margin === 8,
        })}
      >
        <div className="w-full m-2 flex flex-col justify-center">
          <h3 className="text-lg font-bold">{heading}</h3>
          <h4 className="w-[95%] text-sm">{subheading}</h4>
        </div>
        <div className="flex justify-end px-2 py-1">
          <a href={link} target="_blank" rel="noreferrer">
            <Button className="text-sm bg-white border-0 mb-1" rightIcon={<ArrowRight size={18} />}>
              {buttonText}
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
};
